<template>
  <collapse-card :model-value="false">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Territory Details
      </span>
    </template>
    <template #cardContent>
      <div v-if="teritoryDetailsLoading" class="flex justify-center p-40">
          <base-svg
            class="h-4 w-4 mr-1 text-primary-red inline-block"
            src="icons/circleSpinner.svg"
            tag="span"
          />
          Loading ...
      </div>
      <div v-else class="py-2 px-4">
        <div>
          <div class="grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
            <base-input
              v-model="dealSummaryData.territoryDetails.searchKeyword"
              type="text"
              container-class="flex items-center"
              label="Search"
              label-class="label-w-46"
            />
            <div class="flex">
              <base-button
                variant="btn-primary"
                :disabled="!dealSummaryData.territoryDetails.searchKeyword"
                class="mr-3"
                text="Search"
                @click.stop.prevent="fetchTeritoryDetails()"
              />
              <base-button
                variant="btn-link"
                text="Cancel"
                @click="resetSearch()"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end mb-4">
          <base-pagination
            :model-value="dealSummaryData.territoryDetails.tableData.page"
            :total-pages="dealSummaryData.territoryDetails.tableData.pageCount"
            @update:modelValue="handlePageUpdate($event)"
          />
        </div>
        <brand-assurance-table
          v-model="dealSummaryData.territoryDetails.tableData"
          root-element-class="mb-10"
          :show-check-box-column="false"
        />
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent, computed, ref, onMounted } from 'vue';
import useDealSummary from './dealSummary.js';
import { useStore } from 'vuex';
import { formatDate } from '@/helpers/util';

export default {
    name: 'TerritoryDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue'))
    },

    setup () {
        const store = useStore();
        const { dealSummaryData } = useDealSummary();

        const resetSearch = () => {
            dealSummaryData.territoryDetails.searchKeyword = '';
            fetchTeritoryDetails();
        };
        const handlePageUpdate = (newPage) => {
            dealSummaryData.territoryDetails.tableData.page = newPage;
            fetchTeritoryDetails();
        };

        onMounted(() => {
            fetchTeritoryDetails();
        });

        const fetchedTeritoryDetails = ref([])
        const teritoryDetailsLoading = ref(false);
        const fetchTeritoryDetails = async () => {
            try {
                teritoryDetailsLoading.value = true;
                fetchedTeritoryDetails.value = await store.dispatch('baLicensee/fetchDrmTerritoiesDetails', {
                    params: {
                        searchKeyword: dealSummaryData.territoryDetails.searchKeyword,
                        page: dealSummaryData.territoryDetails.tableData.page,
                        limit: dealSummaryData.territoryDetails.tableData.limit
                    },
                    id: dealSummaryData.contractNumber,
                    onlyReturnValues: true,
                });
                dealSummaryData.territoryDetails.tableData.data = fetchedTeritoryDetails.value.data.map(obj => {
                    obj.startDate = formatDate(obj.startDate);
                    obj.endDate = formatDate(obj.endDate);
                    return obj;
                });
                dealSummaryData.territoryDetails.tableData.pageCount = fetchedTeritoryDetails.value.pageCount;
                dealSummaryData.territoryDetails.tableData.totalCount = fetchedTeritoryDetails.value.totalCount;
            } catch (err) {
                console.error(err);
            } finally {
                teritoryDetailsLoading.value = false;
            }
        };
        return {
            dealSummaryData,
            resetSearch,
            handlePageUpdate,
            fetchTeritoryDetails,
            teritoryDetailsLoading
        };
    }
};
</script>
